import React from 'react'
import Seo from '@components/Seo'
import {LANG} from '@constants/theme'
import {IndexTemplate} from '@pages/index'
import '@fontsource/noto-sans-jp'
import '../css/index.css'

export default function IndexPage() {
  return (
    <>
      <Seo
        title={'サムワン ㅡ 1000万回恋に落ちたアプリ'}
        description={'恋人と一緒に使うカップルダイアリー'}
        lang={LANG.ja}
      />
      <IndexTemplate lang={LANG.ja} />
    </>
  )
}
